import AbsoluteCenter from "@/components/generic/absoluteCenter";
import { ButtonWrapper } from "@/components/generic/form/buttonWrapper";
import CredentialsFormWrapper from "@/components/generic/form/credentialsFormWrapper";
import Password from "@/components/generic/form/password";
import SEO from "@/components/generic/seo";
import { useLogout } from "@/components/hooks/useLogout";
import { useRedeemPasswordResetTokenMutation } from "@/generated";
import { passwordRegex, passwordRule } from "@/helper/password";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLocation } from "@reach/router";
import { PageProps } from "gatsby";
import React, { useEffect, useState } from "react";

type PasswordResetPageProps = PageProps;

const PasswordResetPage: React.FC<PasswordResetPageProps> = () => {
  const [username, setUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const logout = useLogout();
  const { href } = useLocation();
  const [redeemToken, { loading }] = useRedeemPasswordResetTokenMutation({ notifyOnNetworkStatusChange: true });

  useEffect(() => {
    //
    // Load url params in useEffect hook to prevent gatsby build errors
    //
    const url = new URL(href);
    setToken(url.searchParams.get("token"));
  }, []);

  const clearInput = (skipUser?: boolean) => {
    if (!skipUser) {
      setUsername("");
    }
    setPassword1("");
    setPassword2("");
  };

  if (!token) {
    return (
      <AbsoluteCenter maxWidth="sm">
        <SEO title="Helaas, er ging iets mis" />
        <CredentialsFormWrapper>
          <Typography variant="h5" component="span">
            Helaas, er gaat iets mis...
          </Typography>
        </CredentialsFormWrapper>
      </AbsoluteCenter>
    );
  }

  return (
    <AbsoluteCenter maxWidth="sm">
      <SEO title="Reset je wachtwoord" />
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (password1 !== password2) {
            clearInput(true);
            setError("Wachtwoorden zijn niet gelijk.");
            return;
          }

          console.log(password1.match(passwordRegex));

          if (!password1.match(passwordRegex)) {
            clearInput(true);
            setError(passwordRule);
            return;
          }

          const { data } = await redeemToken({ variables: { email: username, password: password1, token } });

          if (data?.redeemUserPasswordResetToken?.code === "FAILURE") {
            clearInput();
            setError("Token niet geldig.");
            return;
          }

          if (data?.redeemUserPasswordResetToken?.code === "TOKEN_EXPIRED") {
            clearInput();
            setError("Token is verlopen.");
            return;
          }

          if (data?.redeemUserPasswordResetToken === null) {
            clearInput();
            window.history.replaceState({}, "remove this page from history", "/");
            logout();
          }
        }}
      >
        <CredentialsFormWrapper elevation={7}>
          <Typography variant="h5" component="span">
            Reset je wachtwoord
          </Typography>
          <Typography variant="caption" color="primary" component="span">
            {passwordRule}
          </Typography>
          <TextField
            value={username}
            label="e-mail"
            onChange={(e) => setUsername(e.currentTarget.value)}
            disabled={loading}
          />
          <Password
            id="password1-input"
            value={password1}
            label="nieuw wachtwoord"
            onChange={(e) => setPassword1(e.currentTarget.value)}
            disabled={loading}
          />
          <Password
            id="password2-input"
            value={password2}
            label="herhaal wachtwoord"
            onChange={(e) => setPassword2(e.currentTarget.value)}
            disabled={loading}
          />
          {error && (
            <Typography variant="caption" component="p" color="error.main">
              {error}
            </Typography>
          )}
          {loading && (
            <Typography variant="caption" component="p" color="primary.light">
              Aanvraag in behandeling...
            </Typography>
          )}
          <ButtonWrapper>
            <Button variant="contained" type="submit" disabled={loading || !username || !password1 || !password2}>
              Reset je wachtwoord
            </Button>
          </ButtonWrapper>
        </CredentialsFormWrapper>
      </form>
    </AbsoluteCenter>
  );
};

export default PasswordResetPage;
