import { useLogoutMutation } from "@/generated";

export const useLogout = () => {
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    const res = await logout();

    if (res.data.endSession) {
      if (window !== undefined) {
        window.location.reload();
      }
    }
  };

  return handleLogout;
};
